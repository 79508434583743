<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>报修信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="标题">
                <div style="width: 500px;">
                  {{ formData.title }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="上报人">
                <div style="width: 500px;">
                  {{ formData.reporterName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="手机号码">
                <div style="width: 500px;">
                  {{ formData.telephone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="维修类型">
                <div style="width: 500px;">
                  {{ formData.maintainTypeName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="状态">
                <div style="width: 500px;">
                  <a-tag color="#f50" v-if="formData.status === 0">{{ formData.statusStr }}</a-tag>
                  <a-tag color="#2db7f5" v-else-if="formData.status === 1">{{ formData.statusStr }}</a-tag>
                  <a-tag color="#87d068" v-else-if="formData.status === 2">{{ formData.statusStr }}</a-tag>
                  <a-tag color="#108ee9" v-else-if="formData.status === 3">{{ formData.statusStr }}</a-tag>
                  <a-tag color="#595959" v-else>{{ formData.statusStr }}</a-tag>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="地址">
                <div style="width: 500px;">
                  {{ formData.countyStr }} {{ formData.communityName }} {{ formData.placeDetail }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="上报时间">
                <div style="width: 500px;">
                  {{ formData.createTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="详情">
                <div style="width: 500px;">
                  {{ formData.desc }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="附件">
                <div style="width: 500px;">
                  <p :key="index" v-for="(item, index) in formData.attachments">
                    <img
                      style="max-width: 100%"
                      :src="item"
                    />
                  </p>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { maintainReportInfo } from '@/api/maintain'
export default {
  name: 'SeeMaintain',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {},
      loadingMissonExec: false,
      execList: [],
      moreData: false,
      execParams: {
        formKeyId: 0,
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    dataInfoApi (keyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      maintainReportInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;
  span {
    line-height: 20px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
